/** @format */

import React from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { scheduleSinglePath } from "constants/paths";
import { LvtText, LvtLink } from "components/lvtComponents/components";
import { IconClock } from "components/icons";
import { uniqueId } from "lodash";
import { Localized } from "@fluent/react";
import "./schedules.scss";
import { useGenerateMultilingualData } from "hooks/generic";

const Schedules = props => {
	const { slug } = useParams();

	const schedules = useSelector(state => state.schedules);

	if (schedules.length === 1) {
		return <Redirect to={scheduleSinglePath(slug, schedules[0].id)} />;
	}

	return (
		<NavigationWrapper className="schedules-list">
			<LvtText set="h1" className="lvt-text-h3 schedules-heading">
				<Localized id="navigation-schedule" />
			</LvtText>
			{schedules.map(s => (
				<ScheduleLink {...s} key={uniqueId()} />
			))}
		</NavigationWrapper>
	);
};

const ScheduleLink = props => {
	const { slug } = useParams();
	const { name: fallbackName, display_name = {}, id } = props;
	console.log({ props });
	const { generate } = useGenerateMultilingualData();

	const name = generate(display_name, fallbackName);
	return (
		<LvtLink to={scheduleSinglePath(slug, id)} className="schedule-link">
			<IconClock size="25" className="schedule-icon" />
			{name}
		</LvtLink>
	);
};

export default Schedules;
